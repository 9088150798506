
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import EmployeeModal from "@/components/modals/forms/employee/EmployeeModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "association_contract",
  components: {
    Datatable,
    EmployeeModal,
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const entityInfoID = 5;
    return { entityInfoID };
  },

  data() {
    return {
      batch: {},
      showCourseNotice: false,
      allTableHeader: [
        {
          name: "Selected",
          key: "selected",
          sortable: true,
        },
        {
          name: "Verified",
          key: "verified",
          sortable: true,
        },
        {
          name: "Disversed",
          key: "disversed",
          sortable: false,
        },
      ],
      quarterTableHeader: [
        {
          name: "SEIP Payment Quarter ",
          key: "seip_payment_quarter",
          sortable: true,
        },
        {
          name: "Selected",
          key: "selected",
          sortable: true,
        },
        {
          name: "Verified",
          key: "verified",
          sortable: true,
        },
        {
          name: "Disversed",
          key: "disversed",
          sortable: false,
        },
      ],
      associationTableHeader: [
        {
          name: "Association ",
          key: "seip_payment_quarter",
          sortable: true,
        },
        {
          name: "Selected",
          key: "selected",
          sortable: true,
        },
        {
          name: "Verified",
          key: "verified",
          sortable: true,
        },
        {
          name: "Disversed",
          key: "disversed",
          sortable: false,
        },
      ],
      bankTableHeader: [
        {
          name: "Bank ",
          key: "seip_payment_quarter",
          sortable: true,
        },
        {
          name: "Selected",
          key: "selected",
          sortable: true,
        },
        {
          name: "Verified",
          key: "verified",
          sortable: true,
        },
        {
          name: "Disversed",
          key: "disversed",
          sortable: false,
        },
      ],
      mobileBankingTableHeader: [
        {
          name: "Mobile Banking ",
          key: "seip_payment_quarter",
          sortable: true,
        },
        {
          name: "Selected",
          key: "selected",
          sortable: true,
        },
        {
          name: "Verified",
          key: "verified",
          sortable: true,
        },
        {
          name: "Disversed",
          key: "disversed",
          sortable: false,
        },
      ],
      agentBankingTableHeader: [
        {
          name: "Agent Banking ",
          key: "seip_payment_quarter",
          sortable: true,
        },
        {
          name: "Selected",
          key: "selected",
          sortable: true,
        },
        {
          name: "Verified",
          key: "verified",
          sortable: true,
        },
        {
          name: "Disversed",
          key: "disversed",
          sortable: false,
        },
      ],
      trainingPartnerTableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Bank Account & 80% Attendance",
          key: "bank_account_attendence",
          sortable: false,
        },
        {
          name: "Ethnic (A)",
          key: "ethnic",
          sortable: false,
        },
        {
          name: "Disabled(B)",
          key: "disabled",
          sortable: false,
        },
        {
          name: "Female of Poor District (C)",
          key: "female_poor_district",
          sortable: false,
        },
        {
          name: "Poor (D)",
          key: "poor",
          sortable: false,
        },
        {
          name: "Total (A+B+C+D)",
          key: "total",
          sortable: false,
        },
      ],
      lists: [],
      search: "",
      tableData: [
        {
          sl: 1,
          seip_payment_quarter: "q1",
          selected: 1235,
          verified: 126,
          disversed: 153,
        },
      ],
      associationtableData: [
        {
          sl: 1,
          seip_payment_quarter: "q1",
          selected: 1235,
          verified: 126,
          disversed: 153,
        },
      ],
      banktableData: [
        {
          sl: 1,
          seip_payment_quarter: "q1",
          selected: 1235,
          verified: 126,
          disversed: 153,
        },
      ],
      moblieBankingtableData: [
        {
          sl: 1,
          seip_payment_quarter: "q1",
          selected: 1235,
          verified: 126,
          disversed: 153,
        },
      ],
      agentBankingtableData: [
        {
          sl: 1,
          seip_payment_quarter: "q1",
          selected: 1235,
          verified: 126,
          disversed: 153,
        },
      ],
      trainingPartnertableData: [
        {
          sl: 1,
          name: "q1",
          bank_account_attendence: 1235,
          ethnic: 126,
          disabled: 153,
          female_poor_district: 23,
          poor: 25,
          total: 456,
        },
      ],
      data: {},
      tabIndex: ref(0),
      entityInfos: [],
      entityTypes: [],
    };
  },
  async created() {
    await this.getEntity();
    await this.getEntityTypes();
    await this.getEntityInfos();
    Object.assign(this.tableData, this.lists);
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // courseInfo() {
    //   this.courseInfoData = true;
    // },
    courseNotice() {
      this.showCourseNotice = true;
      console.log(this.batch);
    },
    // trainingProviderNotice() {
    //   this.showtrainingProviderNotice = true;
    // },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));
    },
    async getEntity() {
      await ApiService.get("entity/infos/" + this.entityInfoID)
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // searchItems() {
    //   if (this.search !== "") {
    //     let results: never[] = [];
    //     for (let j = 0; j < this.tableData.length; j++) {
    //       if (this.searchingFunc(this.tableData[j], this.search)) {
    //         results.push(this.tableData[j]);
    //       }
    //     }
    //     this.tableData.splice(0, this.tableData.length, ...results);
    //   } else {
    //     Object.assign(this.tableData, this.lists);
    //   }
    // },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.$router.push({
        name: "entity employee",
        params: {
          id: 1,
        },
      });
    },

    view(id) {
      this.$router.push({
        name: "entityEmployeeProfiles",
        params: {
          entity: this.entityInfoID,
          id: id,
        },
      });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("employee/newProfile/" + `${id}`)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
